<template>
  <DefaultLayout>
    <template #header>
      <ForwardHeader />
    </template>
    <b-row class="justify-content-center justify-content-xl-start">
      <b-col cols="12" xl="4" class="d-flex flex-column align-items-center">
        <div>
          <h1 class="font-size-30 font-weight-semibold font-color-label">Herzlichen Glückwunsch!</h1>
          <h5 class="font-size-18 font-weight-light font-color-label">Dein persönlicher Ausbildungsvertrag ist fertig und wartet auf dein „Go!“</h5>
          <h5 class="font-size-18 font-weight-light font-color-label">
            Bitte checke alle Angaben und bestätige den Ausbildungsvertrag. Im Anschluss kannst du direkt loslegen!
          </h5>
        </div>
        <div class="position-relative img-tile">
          <img :src="require('@/assets/img/present.svg')" alt="" />
          <button class="training-contract-check further-btn" @click="checkCorrectData">
            <span>Ausbildungsvertrag</span><br />
            <span>checken</span>
          </button>
        </div>
      </b-col>
    </b-row>
  </DefaultLayout>
</template>

<script>
import { defineComponent } from "vue";
import DefaultLayout from "@/layout/DefaultLayout";
import ForwardHeader from "@/components/Headers/ForwardHeader";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Congratulations",
  components: { ForwardHeader, DefaultLayout },
  setup() {
    const router = useRouter();

    const checkCorrectData = () => {
      router.push({ name: "PersonalData" });
    };

    return {
      checkCorrectData,
    };
  },
});
</script>
<style lang="scss" scoped>
.training-contract-check {
  position: absolute;
  top: 74%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-tile {
  height: 449px;
  width: 436px;

  img {
    position: absolute;
    left: 20px;
  }
}
</style>
